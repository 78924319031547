import React from 'react'
import Container from '../components/Container'
import PropTypes from 'prop-types';

const Article = ({children}) => {
    return (
        <div className="article">
            <Container>
                <div className="entry">
                    <div className="entry-content">
                    { children }
                    </div>
                </div>                
            </Container>
        </div>
    )
}
Article.propTypes = {
    children: PropTypes.node,
  };
export default Article

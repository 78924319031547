import React from "react";
import SEO from "components/seo";
import Layout from "components/Layout";
import Hero from "components/Hero";
import ContactMe from "components/ContactMe";
import Article from "components/Article";

const About = () => {
  return (
    <Layout pageName="about_us">
      <SEO title="About Us" />
      <Hero title="About Us"></Hero>
      <Article>
        <div className="entry-text">
          <p>
            osTicket software by enhancesoft is the best open-source support
            ticket system out there. While it is excellent in its function, it
            packs a very generic design style, and does not come with a
            responsive layout and UI. <br />
          </p>
          <p>
            At themedost.com I try to address that issue by making custom themes
            for the "Front End" of the osTicket software. The themes come with
            modern design styles and they are responsive and mobile friendly.
            Unlike the generic version of osTicket, these templates gives a
            unique identity to your website and provide your customers a smooth
            interactive experience.
          </p>
        </div>
      </Article>
      <ContactMe></ContactMe>
    </Layout>
  );
};

export default About;
